import { DropdownFilterConfig, FILTER_TYPE } from './DropdownFilterConfig';

import { extractFieldsOfStudy } from '@/constants/FieldOfStudy';
import { FacetType } from '@/constants/FacetType';
import { format } from '@/util';
import { getString } from '@/content/i18n';
import { heapDenseView, heapNormalView } from '@/analytics/attributes/paperObject';
import { isVenueQueryRecord, resetVenueQueryRecord, VenueQueryRecord } from '@/models/VenueQuery';
import { Nullable, ReactNodeish, TODO } from '@/utils/types';
import { QueryRecord, resetQueryRecord } from '@/models/Query';
import { QueryResponseRecord } from '@/models/QueryResponse';
import { QueryStores } from '@/stores/QueryStoresType';
import { VenueQueryResponseRecord } from '@/models/VenueQueryResponse';
import CLButton, { TYPE as BUTTON_TYPE } from '@/components/library/button/CLButton';
import CLDropdown from '@/components/library/dropdown/CLDropdown';
import CLIconButton from '@/components/library/button/CLIconButton';
import CLTextButton from '@/components/library/button/CLTextButton';
import Constants from '@/constants';
import DropdownFilterModal from '@/components/shared/common/modal/DropdownFilterModal';
import EnvInfo from '@/env/EnvInfo';
import EventTarget, { NestedEventTarget } from '@/analytics/constants/EventTarget';
import FilterByYearHistogram from '@/components/shared/histogram/FilterByYearHistogram';
import FilterList from '@/components/shared/facets/FilterList';
import FlexContainer from '@/components/shared/layout/FlexContainer';
import HasViewablePdfFacet from '@/components/shared/facets/HasViewablePdfFacet';
import Icon from '@/components/shared/icon/Icon';
import QueryRoutes from '@/utils/routing/query-routes';
import S2History from '@/utils/S2History';
import ScreenReaderAnnouncement, {
  Politeness,
} from '@/components/util/a11y/ScreenReaderAnnouncement';
import Sort from '@/constants/sort-type';
import UserPreferencesStore, { UserPreference } from '@/stores/UserPreferencesStore';

import classnames from 'classnames';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

type TODO__Router = TODO<'Router object'>;

type Props = {
  doFullQueryReset: boolean;
  query: QueryRecord | VenueQueryRecord;
  response: QueryResponseRecord | VenueQueryResponseRecord;
  config: DropdownFilterConfig;
  className?: Nullable<string>;
  injectQueryStore?: Nullable<QueryStores>;
  showResultCount?: Nullable<boolean>;
  searchControl?: Nullable<ReactNodeish>;
  sortControl?: Nullable<ReactNodeish>;
  isSearchbarFocused: boolean;
  onClearFilters?: Nullable<() => void>;
  onChangeYearFilter: (min: string, max: string) => void;
};

type State = {
  isAllFiltersVisible: boolean;
  isMoreFiltersVisible: boolean;
  isSomeFiltersVisible: boolean;
  clearedFilterMessage: string;
} & StateFromUserPreferencesStore;

type StateFromUserPreferencesStore = {
  isCompact: boolean;
};

const hasIntlAPI = typeof Intl !== 'undefined' && 'NumberFormat' in Intl;

const SORT_TO_DESCRIPTION = Immutable.Map({
  [Sort.RECENCY.id]: getString(_ => _.filterBar.sort.recencyDescription),
  [Sort.TOTAL_CITATIONS.id]: getString(_ => _.filterBar.sort.citationCountDescription),
  [Sort.INFLUENTIAL_CITATIONS.id]: getString(_ => _.filterBar.sort.influenceCountDescription),
});

const CLEARED_MESSAGE_DURATION_MS = 1000;
export default class DropdownFilterControls extends React.Component<Props, State> {
  declare context: {
    analyticsLocation: NestedEventTarget;
    envInfo: EnvInfo;
    history: S2History;
    router: TODO__Router;
    userPrefsStore: UserPreferencesStore;
  };
  static contextTypes = {
    analyticsLocation: PropTypes.object.isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
    router: PropTypes.object.isRequired,
    userPrefsStore: PropTypes.instanceOf(UserPreferencesStore).isRequired,
  };

  constructor(...args: [any]) {
    super(...args);

    this.state = {
      isAllFiltersVisible: false,
      isMoreFiltersVisible: false,
      isSomeFiltersVisible: false,
      clearedFilterMessage: '',
      ...this.stateFromUserPreferencesStore(),
    };

    this.context.userPrefsStore.registerComponent(this, () => {
      this.setState(this.stateFromUserPreferencesStore());
    });
  }

  stateFromUserPreferencesStore() {
    return {
      isCompact: !!this.context.userPrefsStore.getLocalPreference(UserPreference.DENSE_PAPER_VIEW),
    };
  }

  toggleDenseView = () => {
    const { isCompact } = this.state;
    if (isCompact) {
      this.context.userPrefsStore.removeLocalPreference(UserPreference.DENSE_PAPER_VIEW);
    } else {
      this.context.userPrefsStore.setLocalPreference(UserPreference.DENSE_PAPER_VIEW, 1);
    }
  };

  showAllFilters = () => {
    this.setState({ isAllFiltersVisible: true });
  };

  showMoreFilters = () => {
    this.setState({ isMoreFiltersVisible: true });
  };

  showSomeFilters = () => {
    this.setState({ isSomeFiltersVisible: true });
  };

  hideModals = () => {
    this.setState({
      isAllFiltersVisible: false,
      isMoreFiltersVisible: false,
      isSomeFiltersVisible: false,
    });
  };

  onClearFilters = () => {
    const { doFullQueryReset, query } = this.props;
    if (isVenueQueryRecord(query)) {
      QueryRoutes.changeRouteForQuery(
        resetVenueQueryRecord(query),
        this.context.history,
        this.context.router
      );
    } else {
      QueryRoutes.changeRouteForQuery(
        resetQueryRecord(query, !doFullQueryReset, true),
        this.context.history,
        this.context.router
      );
    }

    if (this.props.onClearFilters) {
      this.props.onClearFilters();
    }
    this.setState({
      clearedFilterMessage: getString(_ => _.filterBar.dropdownLabels.clearedFilters),
    });
  };

  onClearAnnouncementComplete = () => {
    this.setState({ clearedFilterMessage: '' });
  };

  isDatesSelected = () => {
    const { response, query } = this.props;
    const allYears = response.stats.get('years');
    if (!allYears || allYears.isEmpty()) {
      return false;
    }
    const minYear = allYears.first().get('year');
    const maxYear = allYears.last().get('year');

    const yearFilter = query.yearFilter;
    return (
      yearFilter &&
      yearFilter.get('min') &&
      yearFilter.get('max') &&
      (yearFilter.get('min') !== minYear || yearFilter.get('max') !== maxYear)
    );
  };

  // Checks if at least one of the given filters has a value set.
  checkFilterStates = filters => {
    const { query } = this.props;
    const isCoAuthorsSelected = query.coAuthors.size > 0 && filters.contains(FILTER_TYPE.COAUTHOR);
    const isAuthorsSelected = query.authors.size > 0 && filters.contains(FILTER_TYPE.AUTHOR);
    let isVenuesSelected = false;
    if (!isVenueQueryRecord(query)) {
      isVenuesSelected = query.venues.size > 0 && filters.contains(FILTER_TYPE.VENUE);
    }
    const isPdfSelected = query.requireViewablePdf && filters.contains(FILTER_TYPE.HAS_PDF);
    const isDatesSelected = this.isDatesSelected() && filters.contains(FILTER_TYPE.DATE);
    const isFieldsOfStudySelected =
      query.fieldsOfStudy.size > 0 && filters.contains(FILTER_TYPE.FIELD_OF_STUDY);
    return (
      isCoAuthorsSelected ||
      isAuthorsSelected ||
      isVenuesSelected ||
      isPdfSelected ||
      isDatesSelected ||
      isFieldsOfStudySelected
    );
  };

  shouldShowFilter = filter => {
    const {
      envInfo: { isMobile },
    } = this.context;
    return this.props.config.startVisible.contains(filter) && !isMobile;
  };

  shouldShowFilterWithStat = (filter, stat) => {
    const {
      envInfo: { isMobile },
    } = this.context;

    return (
      this.props.config.startVisible.contains(filter) && !!stat && !stat.isEmpty() && !isMobile
    );
  };

  // eslint-disable-next-line max-lines-per-function
  render() {
    const {
      config,
      response,
      query,
      className,
      injectQueryStore,
      onChangeYearFilter,
      showResultCount,
      searchControl,
      sortControl,
      isSearchbarFocused,
    } = this.props;

    const { isCompact } = this.state;

    const {
      analyticsLocation,
      envInfo: { isMobile },
    } = this.context;

    const coAuthors = response.stats.get(FacetType.COAUTHOR.pluralId) || Immutable.Set();
    const authors = response.stats.get(FacetType.AUTHOR.pluralId);
    const venues = response.stats.get(FacetType.VENUE.pluralId);
    const fieldsOfStudy = extractFieldsOfStudy(
      response.stats.get(FacetType.FIELDS_OF_STUDY.pluralId)
    );

    const coAuthorTotalCount = coAuthors
      .toList()
      .map(item => {
        return item.get('documentCount');
      })
      .reduce((sum, x) => sum + x, 0);

    const isCoAuthorsSelected = query.coAuthors.size > 0;
    const isAuthorsSelected = query.authors.size > 0;
    let isVenuesSelected = false;
    if (!isVenueQueryRecord(query)) {
      isVenuesSelected = query.venues.size > 0;
    }
    const isFieldsOfStudySelected = query.fieldsOfStudy.size > 0;
    const isDatesSelected = this.isDatesSelected();

    const isSomeSelected = this.checkFilterStates(config.startInModal);
    const isMoreSerpSelected = this.checkFilterStates(config.collapseToModal);
    const isAnySelected = this.checkFilterStates(config.startVisible.concat(config.startInModal));
    const isSortControlPresent = !!sortControl;

    const formattedResultCount = hasIntlAPI
      ? new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(
          response.totalResults
        )
      : format(response.totalResults);

    const hasFiltersText = isAnySelected ? getString(_ => _.filterBar.activeFilters) : '';
    const queryString = isVenueQueryRecord(query) ? query.name : query.queryString;

    const resultCountLine =
      response.totalResults >= 1000
        ? getString(
            _ => _.filterBar.approxResultCount,
            formattedResultCount,
            queryString,
            hasFiltersText
          )
        : response.totalResults > 1
          ? getString(
              _ => _.filterBar.exactResultCount,
              formattedResultCount,
              queryString,
              hasFiltersText
            )
          : getString(_ => _.filterBar.singleResultCount, queryString, hasFiltersText);

    const sortDescription =
      query.sort != Sort.RELEVANCE.id && SORT_TO_DESCRIPTION.get(query.sort) != undefined
        ? SORT_TO_DESCRIPTION.get(query.sort)
        : null;
    return (
      <div className={classnames('dropdown-filters', 'dropdown-filters__controls', className)}>
        {!!showResultCount && (
          <h1 aria-live="polite" aria-atomic="true" className="dropdown-filters__result-header">
            <div className="dropdown-filters__result-count">{resultCountLine}</div>
            {sortDescription && (
              <div
                className={classnames(
                  'dropdown-filters__sort-description',
                  isMobile ? 'dropdown-filters__sort-description__mobile' : ''
                )}>
                {sortDescription}
              </div>
            )}
          </h1>
        )}
        <div className="centered-max-width-content">
          <FlexContainer className="flex-row-vcenter dropdown-filters__outer-flex-container">
            <FlexContainer className="flex-row-vcenter dropdown-filters__filter-flex-container">
              {!!searchControl && searchControl}
              {!isSearchbarFocused && (
                <React.Fragment>
                  {this.shouldShowFilter(FILTER_TYPE.FIELD_OF_STUDY) && (
                    <CLDropdown
                      className="dropdown-filters__fields_of_study"
                      label={getString(_ => _.filterBar.dropdownTitles.fieldOfStudy)}
                      type={isFieldsOfStudySelected ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.DEFAULT}
                      contents={() => (
                        <FilterList
                          title={getString(_ => _.filterBar.dropdownTitles.fieldOfStudy)}
                          filterType={FacetType.FIELDS_OF_STUDY.pluralId}
                          // @ts-expect-error -- According to TS, we pass the wrong type. But this is what works today.
                          filters={fieldsOfStudy}
                          displayDocCount={false}
                          collapsible={false}
                          injectQueryStore={injectQueryStore}
                          hasSelection={isFieldsOfStudySelected}
                        />
                      )}
                    />
                  )}
                  {this.shouldShowFilter(FILTER_TYPE.DATE) && (
                    <CLDropdown
                      className="dropdown-filters__dates"
                      label={getString(_ => _.filterBar.dropdownLabels.date)}
                      type={isDatesSelected ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.DEFAULT}
                      contents={() => (
                        <FilterByYearHistogram
                          analyticsEvent={EventTarget.getIn(analyticsLocation, 'YEAR_SLIDER')}
                          centerBucketPopover
                          filterCallback={onChangeYearFilter}
                          filters={response.stats}
                          yearFilter={query.yearFilter}
                          yearBuckets={null}
                          showPresetButtons
                        />
                      )}
                    />
                  )}
                  {this.shouldShowFilterWithStat(FILTER_TYPE.COAUTHOR, coAuthors) && (
                    <CLDropdown
                      disabled={coAuthorTotalCount == 0}
                      className="dropdown-filters__coauthor"
                      label={getString(_ => _.filterBar.dropdownLabels.coAuthor)}
                      type={isCoAuthorsSelected ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.DEFAULT}
                      contents={() => (
                        <FilterList
                          title={getString(_ => _.filterBar.dropdownTitles.coAuthor)}
                          filterType={FacetType.COAUTHOR.id}
                          filters={coAuthors}
                          collapsible={false}
                          injectQueryStore={injectQueryStore}
                        />
                      )}
                    />
                  )}
                  {this.shouldShowFilter(FILTER_TYPE.HAS_PDF) && (
                    <HasViewablePdfFacet
                      className="has-pdf-toggle"
                      isToggle={true}
                      injectQueryStore={injectQueryStore}
                    />
                  )}
                  {this.shouldShowFilterWithStat(FILTER_TYPE.AUTHOR, authors) && (
                    <CLDropdown
                      className="dropdown-filters__author"
                      label={getString(_ => _.filterBar.dropdownLabels.author)}
                      type={isAuthorsSelected ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.DEFAULT}
                      contents={() => (
                        <FilterList
                          title={getString(_ => _.filterBar.dropdownTitles.author)}
                          filterType={FacetType.AUTHOR.id}
                          filters={authors}
                          collapsible={false}
                          injectQueryStore={injectQueryStore}
                          hasSelection={isAuthorsSelected}
                        />
                      )}
                    />
                  )}
                  {this.shouldShowFilterWithStat(FILTER_TYPE.VENUE, venues) && (
                    <CLDropdown
                      className="dropdown-filters__venues"
                      label={getString(_ => _.filterBar.dropdownLabels.venue)}
                      type={isVenuesSelected ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.DEFAULT}
                      contents={() => (
                        <FilterList
                          title={getString(_ => _.filterBar.dropdownTitles.venue)}
                          filterType={FacetType.VENUE.id}
                          filters={venues}
                          collapsible={false}
                          maxLabelLength={Constants.data.MAX_VENUE_LENGTH}
                          injectQueryStore={injectQueryStore}
                          hasSelection={isVenuesSelected}
                        />
                      )}
                    />
                  )}
                  {!this.props.config.startInModal.isEmpty() && (
                    <div className="dropdown-filters__modal-button flex-item">
                      <CLButton
                        className="dropdown-filters__some_filters"
                        type={isSomeSelected ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.SECONDARY}
                        label={getString(_ => _.filterBar.dropdownLabels.moreFilters)}
                        onClick={this.showSomeFilters}
                      />
                    </div>
                  )}
                  <div className="dropdown-filters__modal-button flex-item">
                    <CLButton
                      className="dropdown-filters__more_filters"
                      type={isMoreSerpSelected ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.SECONDARY}
                      label={getString(_ => _.filterBar.dropdownLabels.moreFilters)}
                      onClick={this.showMoreFilters}
                    />
                  </div>
                  <div className="dropdown-filters__modal-button">
                    <CLButton
                      className="dropdown-filters__mobile_filters"
                      type={isAnySelected ? BUTTON_TYPE.PRIMARY : BUTTON_TYPE.SECONDARY}
                      label={getString(_ => _.filterBar.dropdownLabels.allFilters)}
                      onClick={this.showAllFilters}
                    />
                  </div>
                  {(isAnySelected || (this.props.config.includeClearOnQuery && queryString)) && (
                    <CLTextButton
                      ariaProps={{
                        'aria-label': getString(_ => _.filterBar.dropdownLabels.clearFilters),
                      }}
                      label={getString(_ => _.filterBar.dropdownLabels.clear)}
                      onClick={this.onClearFilters}
                      data-test-id="clear-filters-button"
                    />
                  )}

                  <ScreenReaderAnnouncement
                    message={this.state.clearedFilterMessage}
                    politeness={Politeness.ASSERTIVE}
                    durationMs={CLEARED_MESSAGE_DURATION_MS}
                    onAnnouncementCompletion={this.onClearAnnouncementComplete}
                  />
                </React.Fragment>
              )}
            </FlexContainer>
            <FlexContainer className="flex-row-vcenter dropdown-filters__sort-flex-container">
              {isSortControlPresent && sortControl}
              {!isMobile && (
                <FlexContainer className="dropdown-filters__density-toggle">
                  <CLIconButton
                    ariaProps={{
                      'aria-label': getString(_ => _.filterBar.densityToggle.regularViewAria),
                    }}
                    className="dropdown-filters__density-toggle-off"
                    disabled={!isCompact}
                    {...heapNormalView()}
                    onClick={this.toggleDenseView}
                    type={BUTTON_TYPE.DEFAULT}
                    icon={<Icon icon="view-regular" height="14" width="14" />}
                  />
                  <CLIconButton
                    ariaProps={{
                      'aria-label': getString(_ => _.filterBar.densityToggle.denseViewAria),
                    }}
                    className="dropdown-filters__density-toggle-on"
                    disabled={isCompact}
                    {...heapDenseView()}
                    onClick={this.toggleDenseView}
                    type={BUTTON_TYPE.DEFAULT}
                    icon={<Icon icon="view-dense" height="14" width="14" />}
                  />
                </FlexContainer>
              )}
            </FlexContainer>
          </FlexContainer>
        </div>
        {this.state.isSomeFiltersVisible && (
          <DropdownFilterModal
            filters={config.startInModal}
            onChangeYearFilter={onChangeYearFilter}
            onClearFilters={this.onClearFilters}
            onHideClick={this.hideModals}
            query={query}
            response={response}
            injectQueryStore={injectQueryStore}
          />
        )}
        {this.state.isMoreFiltersVisible && (
          <DropdownFilterModal
            filters={config.startInModal.concat(config.collapseToModal)}
            onChangeYearFilter={onChangeYearFilter}
            onClearFilters={this.onClearFilters}
            onHideClick={this.hideModals}
            query={query}
            response={response}
            injectQueryStore={injectQueryStore}
          />
        )}
        {this.state.isAllFiltersVisible && (
          <DropdownFilterModal
            allFilters
            searchControl={searchControl}
            filters={config.startInModal.concat(config.startVisible)}
            onChangeYearFilter={onChangeYearFilter}
            onClearFilters={this.onClearFilters}
            onHideClick={this.hideModals}
            query={query}
            response={response}
            injectQueryStore={injectQueryStore}
          />
        )}
      </div>
    );
  }
}
