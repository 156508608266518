import { extractFieldsOfStudy } from '@/constants/FieldOfStudy';
import { FILTER_TYPE } from '@/components/shared/filter/DropdownFilterConfig';
import { getString } from '@/content/i18n';
import CLButton from '@/components/library/button/CLButton';
import CLModal from '@/components/library/modal/CLModal';
import CLTextButton from '@/components/library/button/CLTextButton';
import Constants from '@/constants';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import FilterByYearHistogram from '@/components/shared/histogram/FilterByYearHistogram';
import FilterList from '@/components/shared/facets/FilterList';
import HasViewablePdfFacet from '@/components/shared/facets/HasViewablePdfFacet';
import S2Dispatcher from '@/utils/S2Dispatcher';
import S2History from '@/utils/S2History';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default class DropdownFilterModal extends React.PureComponent {
  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
    router: PropTypes.object.isRequired,
  };

  render() {
    const {
      allFilters,
      filters,
      searchControl,
      query,
      response,
      onChangeYearFilter,
      onHideClick,
      injectQueryStore,
    } = this.props;

    const isMobile = this.context.envInfo.isMobile;
    const coAuthors = response.stats.get('coAuthors');
    const authors = response.stats.get('authors');
    const venues = response.stats.get('venues');
    const fieldsOfStudy = extractFieldsOfStudy(response.stats.get('fieldsOfStudy'));
    const ariaProps = { 'aria-modal': 'true' };

    return (
      <CLModal
        ariaProps={ariaProps}
        modalId={null}
        className={classnames({
          'dropdown-filter-modal': true,
          'dropdown-filter-modal__mobile': isMobile,
        })}
        onHideClick={onHideClick}>
        <div className="dropdown-filter-modal__container">
          <div className="dropdown-filter-modal__filters">
            <h2 className="dropdown-filter-modal__header">
              {allFilters
                ? getString(_ => _.filterBar.dropdownLabels.allFilters)
                : getString(_ => _.filterBar.dropdownLabels.moreFilters)}
            </h2>

            {!!searchControl && <div className="search-container">{searchControl}</div>}

            {filters.contains(FILTER_TYPE.DATE) && (
              <FilterByYearHistogram
                analyticsEvent={EventTarget.Serp.YEAR_SLIDER}
                centerBucketPopover
                filterCallback={onChangeYearFilter}
                filters={response.stats}
                histogramWidth={200}
                yearFilter={query.yearFilter}
                yearBuckets={null}
                showPresetButtons
              />
            )}

            {filters.contains(FILTER_TYPE.HAS_PDF) && <HasViewablePdfFacet />}

            {filters.contains(FILTER_TYPE.FIELD_OF_STUDY) &&
              !!fieldsOfStudy &&
              !fieldsOfStudy.isEmpty() && (
                <FilterList
                  title={getString(_ => _.filterBar.dropdownTitles.fieldOfStudy)}
                  filterType="fieldsOfStudy"
                  filters={fieldsOfStudy}
                  collapsible={false}
                  injectQueryStore={injectQueryStore}
                />
              )}

            {filters.contains(FILTER_TYPE.COAUTHOR) && !!coAuthors && !coAuthors.isEmpty() && (
              <FilterList
                title={getString(_ => _.filterBar.dropdownTitles.coAuthor)}
                filterType="coAuthor"
                filters={coAuthors}
                collapsible={false}
                injectQueryStore={injectQueryStore}
              />
            )}

            {filters.contains(FILTER_TYPE.AUTHOR) && !!authors && !authors.isEmpty() && (
              <FilterList
                title={getString(_ => _.filterBar.dropdownTitles.author)}
                filterType="author"
                filters={authors}
                collapsible={false}
                injectQueryStore={injectQueryStore}
              />
            )}
            {filters.contains(FILTER_TYPE.VENUE) && !!venues && !venues.isEmpty() && (
              <FilterList
                title={getString(_ => _.filterBar.dropdownTitles.venue)}
                filterType="venue"
                filters={venues}
                collapsible={false}
                maxLabelLength={Constants.data.MAX_VENUE_LENGTH}
                injectQueryStore={injectQueryStore}
              />
            )}
          </div>

          <div className="dropdown-filter-modal__footer">
            <CLTextButton
              label={getString(_ => _.filterBar.dropdownLabels.clearFilters)}
              onClick={this.props.onClearFilters}
            />
            <CLButton
              type="primary"
              label={getString(_ => _.filterBar.dropdownLabels.applyFilters)}
              onClick={onHideClick}
            />
          </div>
        </div>
      </CLModal>
    );
  }
}
