import { SortRecord } from '@/models/Sort';
import sortType from '@/constants/sort-type';

import Immutable from 'immutable';
import merge from 'merge';

/**
 * Configurable options for a sortable, filterable list of search results.
 * Defaults to the SERP layout.
 *
 * @param {boolean} showSortExplanation Show a help icon and tooltip explaining the various
 * sort options.
 * @param {string} sortDisplayType Display list sort as 'select' or 'tabs'.
 * @param {boolean} [showResultCount=true] Whether to display the result count or not.
 * @param {boolean} scrollToTopOfPage Whether to return to the top of the page on paginate
 * @param {object} searchResultLayout Options specific to the layout of individual result items.
 * @param {boolean} searchResultLayout.showSortStat Whether or not we should display the statistic
 * currently be sorted by.
 * @param {Sort[]=sortType.paperSearch()} sorts Optional array of available sorts.
 *
 * TODO(codeviking): There's a bug with out default props are handled. If the searchResultLayout
 * options are specified, only the keys defined by the implementer are set.  This means that the
 * defaults (like showBadges: true) aren't "merged" into the impelmentor's declaration.  Ideally
 * this wouldn't be the case.
 */

type Props = {
  showSortExplanation: boolean;
  showResultCount: boolean;
  sortDisplayType: string;
  scrollToTopOfPage: boolean;
  searchResultLayout: {
    showSortStat: boolean;
  };
  sorts: Immutable.List<SortRecord>;
  filtersExpandedByDefault: boolean;
};

export default class SearchLayoutOptions {
  showSortExplanation: boolean;
  showResultCount: boolean;
  sortDisplayType: string;
  scrollToTopOfPage: boolean;
  searchResultLayout: {
    showSortStat: boolean;
  };
  sorts: Immutable.List<SortRecord>;
  filtersExpandedByDefault: boolean;

  constructor(
    props: Props = {
      showSortExplanation: false,
      showResultCount: true,
      sortDisplayType: 'select',
      scrollToTopOfPage: true,
      searchResultLayout: {
        showSortStat: false,
      },
      sorts: Immutable.List<SortRecord>(),
      filtersExpandedByDefault: false,
    }
  ) {
    if (!props.sorts || props.sorts.size === 0) {
      props.sorts = sortType.paperSearch();
    }

    merge(this, props);
  }
}
